import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PreferencesService } from '../services/preferences.service';
import { STORAGE_BOOK_SHELF_SETTINGS } from './constants';

export enum BookShelfDisplayType {
  Line = 'line',
  Grid = 'grid',
}

interface BookShelfSettings {
  displayType: BookShelfDisplayType;
}

@Injectable({ providedIn: 'root' })
export class BookShelfSettingsState {
  constructor(private preferenceService: PreferencesService) {}

  private readonly STORAGE_KEY = STORAGE_BOOK_SHELF_SETTINGS;
  private readonly initialSettings: BookShelfSettings = {
    displayType: BookShelfDisplayType.Grid,
  };

  bookShelfSettings$!: BehaviorSubject<BookShelfSettings>;

  async initialize(): Promise<void> {
    const storedSettings = await this.get();
    this.bookShelfSettings$ = new BehaviorSubject<BookShelfSettings>(storedSettings || this.initialSettings);

    if (!storedSettings) {
      await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(this.initialSettings));
    }

    return Promise.resolve();
  }

  private async get(): Promise<BookShelfSettings | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<BookShelfSettings>): Promise<void> {
    const updatedOptions: BookShelfSettings = {
      ...this.bookShelfSettings$?.value,
      ...settings,
    };

    this.bookShelfSettings$.next(updatedOptions);
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }
}
